<template>
  <template v-if="['UInfoCard', 'faq'].includes(identifyComponent)">
    <v-row
      no-gutters
      justify-sm="center"
    >
      <v-col
        cols="24"
        sm="20"
        md="18"
        lg="16"
        class="space-y-6"
      >
        <template
          v-for="(blok, index) in content"
          :key="index"
        >
          <template v-if="['UInfoCard'].includes(blok.component)">
            <u-info-card
              variant="white"
              :title="blok.title"
              :text="blok.text"
              :step="`${index + 1}`"
            >
              <template
                v-if="blok.icon"
                #append
              >
                <v-img
                  :src="blok.icon.filename"
                  max-width="117"
                />
              </template>
            </u-info-card>
          </template>
          <template v-else>
            <storyblok-component
              :blok="blok"
              :gtm="{ event: 'gaEvent', category: 'homepage', action: 'click', label: 'homepage_faq' }"
            />
          </template>
        </template>
      </v-col>
    </v-row>
  </template>
  <template v-else-if="['UFeatureCard'].includes(identifyComponent)">
    <div class="grid max-lg:grid-rows-3 lg:grid-cols-3 gap-6 lg:gap-x-10">
      <template
        v-for="(card, index) in content"
        :key="index"
      >
        <u-feature-card
          :title="card.title"
          :text="card.text"
          :icon="card.icon"
          :icon-color="card.iconColor"
        />
      </template>
    </div>
  </template>
</template>

<script setup lang="ts">
import type { HomeSectionStoryblok } from '#shared/types/storyblok-component-types'

const { content } = defineProps<{
  content: HomeSectionStoryblok['content']
}>()

const identifyComponent = computed(() => {
  if (!content || content.length === 0) {
    return
  }

  return content[0]!.component
})
</script>
